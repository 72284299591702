.App {
    font-family: sans-serif;
    text-align: center;
}
.container {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
}
h2,
h3 {
    font-weight: normal;
}
h2 {
    margin: 0;
}
h3 {
    margin-top: 10px;
}
.reload {
    margin: 10px 0 20px;
    font-size: 12px;
    border: solid 1px #c00;
    padding: 4px 8px;
}
